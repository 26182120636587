import React, { useState, handlelick, active, useContext } from "react";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Context } from "../../utils/context";
import { IDS } from "../../utils/common";
library.add(fas);

const Sidebar = () => {
  const { isSidebarOpen, setSidebarOpen, isAllow, usertype } = useContext(Context);
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(true);
  const [showItemNames, setShowItemNames] = useState(true);
  const [headerText, setHeaderText] = useState(location.pathname);
  console.log(usertype, "in sidebar usertype");
  useEffect(() => {
    setHeaderText(location.pathname);
  });

  const [active, setActive] = useState("0");
  const handleClick = (eventKey) => {
    if (active === eventKey) {
      setActive(null);
    } else {
      setActive(eventKey);
      // setSidebarOpen(true);
    }
  };

  const isActive = (path) => {
    return location.pathname.includes(path) ? "active block" : "none";
  };

  const [show, setShow] = useState(false);
  return (
    <>
      <section className="sidebar">

        <div className="desktop-view-sidebar">
          <div className="top_section">
            <img
              className="logo ms-2"
              src={process.env.PUBLIC_URL + "/Local-MandaiFinal-Logo.png"}
            />
          </div>
          <ul className="list-unstyled ps-0">
            {/* ***************************************************Dashboard*************************************************************** */}

            <Accordion
              // defaultActiveKey="0"

              data-bs-toggle="collapse"
              data-bs-target="#home-collapse"
              aria-expanded="false"
            >
              <Accordion.Item
                eventKey="0"
                onClick={() => { handleClick("0"); setSidebarOpen(true) }}
              >
                <Accordion.Header>
                  <div className=" me-3">
                    <FontAwesomeIcon
                      icon="fa-solid fa-house"

                    />
                  </div>
                  <p
                    className="sidebar_txt"
                    style={{ display: isSidebarOpen ? "block" : "none" }}

                  >
                    Dashboard
                  </p>

                  {/* </button> */}
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <ul
                    className={`accordion_list list-unstyled fw-normal  small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                      }`}
                    style={{
                      display: isSidebarOpen ? "block" : "none",
                    }}
                  >
                    <Link to="/advanceDashboard" className="text-decoration-none">
                      <li className={isActive("/advanceDashboard")}>
                        <p key={2} className="insideDropdown_list" id={"2"}>
                          Advance Dashboard
                        </p>
                      </li>
                    </Link>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>



              {/* ************************************************************   dashboard End ************************************************************* */}

              {/* ************************************************************  website Type  ************************************************************* */}
              {isAllow?.includes(79) ||
                isAllow?.includes(80) ||
                isAllow?.includes(81) ? (
                <Accordion.Item
                  eventKey="11"
                  onClick={() => { handleClick("11"); setSidebarOpen(true) }}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-copyright"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                      onClick={() => setSidebarOpen(true)}
                    >
                      Website Type
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className={`accordion_list list-unstyled fw-normal  small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                        }`}
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                    >
                      <Link
                        to="/settings/website-type"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/settings/website-type")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Website Type
                          </p>
                        </li>
                      </Link>

                    </ul>
                  </Accordion.Body>

                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* *********************************************************Dashboard Ends*************************************************************** */}
              {isAllow?.includes(63) || isAllow?.includes(114) || isAllow?.includes(64) || isAllow?.includes(62) || isAllow?.includes(61) ? (
                <Accordion.Item
                  eventKey="20"
                  className=""
                  onClick={() => handleClick("20")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"
                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Career
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >
                      {isAllow?.includes(61) ? (
                        <Link
                          to="/career/work-module"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/career/work-module")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Language
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(62) ? (
                        <Link
                          to="/career/job-type"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/career/job-type")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Job Type
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(63) ? (
                        <Link
                          to="/career/department"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/career/department")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Shifts
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(64) ? (
                        <Link
                          to="/career/qualification"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/career/qualification")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Qualification
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(114) ? (
                        <Link
                          to="/career/jobs"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/career/jobs")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Jobs
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************  Orders ************************************************************* */}
              {isAllow?.includes(111) ||
                isAllow?.includes(107) ||
                isAllow?.includes(109) ||
                isAllow?.includes(110) ? (
                <Accordion.Item
                  eventKey="1"
                  onClick={() => { handleClick("1"); setSidebarOpen(true) }}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon icon="fa fa-shopping-cart" />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Orders
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul className={`accordion_list list-unstyled fw-normal  small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                      }`}
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}>
                      {isAllow?.includes(107) ? (
                        <Link
                          to="/order-managements/order"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/order-managements/order")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Order
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(111) ? (

                        <Link
                          to="/order-managements/order/return"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/order-managements/order/return")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >

                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Returned Order
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}

                      {/* <Link
                    to="/order-managements/order/custome"
                    className=" text-decoration-none  "
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes("/order-managements/order/custome")
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    >
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                       Custome Order 
                      </p>
                    </li>
                  </Link> */}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {/* ************************************************************  Orders End ************************************************************* */}

              {/* ************************************************************  Categories ************************************************************* */}
              {isAllow?.includes(29) ||
                isAllow?.includes(33) ||
                isAllow?.includes(34) ? (
                <Accordion.Item
                  eventKey="2" onClick={() => { handleClick("2"); setSidebarOpen(true) }}>
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon icon="fa fa-bullseye" />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Categories
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul className={`accordion_list list-unstyled fw-normal  small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                      }`}
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}>
                      {isAllow?.includes(29) ? (
                        <Link
                          to="/settings/category"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/category")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Category
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(33) ? (
                        <Link
                          to="/settings/sub-category"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/sub-category")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >

                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Sub Category
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}

                      {/* <Link
                        to="/settings/child-sub-category"
                        className=" text-decoration-none  "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/settings/child-sub-category")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Child Sub Category
                          </p>
                        </li>
                      </Link> */}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************  Categories End ************************************************************* */}

              {/* ************************************************************  Products ************************************************************* */}
              {isAllow?.includes(53) ||
                isAllow?.includes(57) ||
                isAllow?.includes(75) ||
                isAllow?.includes(87) ||
                isAllow?.includes(99) ||
                isAllow?.includes(101) ||
                isAllow?.includes(126) ||
                isAllow?.includes(118) ||
                isAllow?.includes(122) 
               ? (
                <Accordion.Item
                  eventKey="3"
                  onClick={() => { handleClick("3"); setSidebarOpen(true) }}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa fa-cubes"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Products
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className={`accordion_list list-unstyled fw-normal  small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                        }`}
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                    >
                      {isAllow?.includes(87) ? (
                        <Link
                          to="product"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen && headerText === "/product" ? "active block" : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Product
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}


                      {isAllow?.includes(99) ?
                        (
                          <Link
                            to="/product-requests"
                            className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("/product-requests")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            >
                              <p
                                className="insideDropdown_list"
                                style={{ display: isOpen ? "block" : "none" }}
                              >
                                Product Requests
                              </p>
                            </li>
                          </Link>
                        ) : (
                          <></>
                        )}
                      {isAllow?.includes(101) ?
                        (
                          <Link
                            to="/product-rejected"
                            className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("/product-rejected")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            >
                              <p
                                className="insideDropdown_list"
                                style={{ display: isOpen ? "block" : "none" }}
                              >
                                Product Rejected
                              </p>
                            </li>
                          </Link>
                        ) : (
                          <></>
                        )}

                      {isAllow?.includes(75) ? (
                        <Link
                          to="/product-type"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/product-type")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Product Type
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(53) ?
                        (
                          <Link
                            to="/settings/tax/tax-type"
                            className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("/settings/tax/tax-type")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            >
                              <p
                                className="insideDropdown_list"
                                style={{ display: isOpen ? "block" : "none" }}
                              >
                                Tax Types
                              </p>
                            </li>
                          </Link>
                        ) : (
                          <></>
                        )}

                      {isAllow?.includes(57) ?
                        (
                          <Link
                            to="/settings/tax/tax-percentage"
                            className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("/settings/tax/tax-percentage")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            >
                              <p
                                className="insideDropdown_list"
                                style={{ display: isOpen ? "block" : "none" }}
                              >
                                Tax Percentage
                              </p>
                            </li>
                          </Link>
                        ) : (
                          <></>
                        )}
                      {isAllow?.includes(122) ?
                        (
                          <Link
                            to="/settings/occasion"
                            className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("/settings/occasion")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            >
                              <p
                                className="insideDropdown_list"
                                style={{ display: isOpen ? "block" : "none" }}
                              >
                                Occasion
                              </p>
                            </li>
                          </Link>
                        ) : (
                          <></>
                        )}

                      {isAllow?.includes(118) ?
                        (
                          <Link
                            to="/settings/material"
                            className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("/settings/material")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            >
                              <p
                                className="insideDropdown_list"
                                style={{ display: isOpen ? "block" : "none" }}
                              >
                                Material
                              </p>
                            </li>
                          </Link>
                        ) : (
                          <></>
                        )}

                      {isAllow?.includes(126) ?
                        (
                          <Link
                            to="/settings/cloth-size"
                            className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("/settings/cloth-size")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            >
                              <p
                                className="insideDropdown_list"
                                style={{ display: isOpen ? "block" : "none" }}
                              >
                                Cloth Size
                              </p>
                            </li>
                          </Link>
                        ) : (
                          <></>
                        )}


                    </ul>
                  </Accordion.Body>

                </Accordion.Item>
              ) : (
                <></>
              )}

              {/* ************************************************************  Products End ************************************************************* */}

              {/* ************************************************************  Sellers ************************************************************* */}
              {isAllow?.includes(5) ||
                isAllow?.includes(6) ||
                isAllow?.includes(7) ||
                isAllow?.includes(8) ? (
                <Accordion.Item
                  eventKey="4"
                  onClick={() => { handleClick("4"); setSidebarOpen(true) }}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa fa-store"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Sellers
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className={`accordion_list list-unstyled fw-normal  small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                        }`}
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                    >


                      {isAllow?.includes(5) ? (
                        <Link
                          to="/employee/employee-requests"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/employee-requests")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Seller Request
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}


                      {/* <Link
                        to="/employee/employee-details"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/employee/employee-details")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Seller Details
                          </p>
                        </li>
                      </Link> */}
                      {isAllow?.includes(7) ? (
                        <Link
                          to="/seller-detail"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/seller-detail")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Sellers Details
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(8) ? (
                        <Link
                          to="/employee/employee-rejected"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/employee-rejected")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Rejected Requests
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}




                    </ul>
                  </Accordion.Body>

                </Accordion.Item>
              ) : (
                <></>
              )}

              {/* ************************************************************  Sellers End ************************************************************* */}
              {/* ************************************************************  Sellers ************************************************************* */}
              {isAllow?.includes(69) ||
                isAllow?.includes(70) ||
                isAllow?.includes(71) ||
                isAllow?.includes(83) ||
                isAllow?.includes(84) ||
                isAllow?.includes(85) ||
                isAllow?.includes(86) ||
                isAllow?.includes(91) ||
                isAllow?.includes(92) ||
                isAllow?.includes(72) ? (
                <Accordion.Item
                  eventKey="18"
                  onClick={() => { handleClick("18"); setSidebarOpen(true) }}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa fa-users"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Customers
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className={`accordion_list list-unstyled fw-normal  small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                        }`}
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                    >
                      {isAllow?.includes(69) ? (
                        <Link
                          to="/employee/customer-details"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/customer-details")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Customer Details
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(70) ? (
                        <Link
                          to="/employee/contact-us"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/contact-us")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Contact Us
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(71) ? (
                        <Link
                          to="/employee/customizeform"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/customizeform")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Customisation
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(72) ? (
                        <Link
                          to="/employee/made-to-order"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/made-to-order")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Made To Order
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(83) ? (
                        <Link
                          to="/employee/talk-to-us"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/talk-to-us")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Talk To Us
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(84) ? (
                        <Link
                          to="/employee/suggestion"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/suggestion")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Suggestion
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(85) ? (
                        <Link
                          to="/employee/rfq"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/rfq")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              RFQ
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(86) ? (
                        <Link
                          to="/employee/responsibility"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/responsibility")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Responsibility
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(91) ? (
                        <Link
                          to="/employee/jobs"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/jobs")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Jobs
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(92) ? (
                        <Link
                          to="/employee/product-query"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/product-query")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Product Care
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}




                    </ul>
                  </Accordion.Body>

                </Accordion.Item>
              ) : (
                <></>
              )}

              {/* ************************************************************  Sellers End ************************************************************* */}

              {/* ************************************************************  Customization ************************************************************* */}
              {isAllow?.includes(93) ||
                isAllow?.includes(94) ||
                isAllow?.includes(25) ||
                isAllow?.includes(26) ||
                isAllow?.includes(27) ||
                isAllow?.includes(28) ? (
                <Accordion.Item
                  eventKey="5"
                  onClick={() => { handleClick("5"); setSidebarOpen(true) }}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-sliders"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Masters
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className={`accordion_list list-unstyled fw-normal  small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                        }`}
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                    >
                      {/* <Link
                        to="/settings/customization/web-header"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/settings/customization/web-header")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Web Header
                          </p>
                        </li>
                      </Link> */}


                      <Link
                        to="/app-setup"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/app-setup")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            App Setup
                          </p>
                        </li>
                      </Link>
                      {isAllow?.includes(93) ? (
                        <Link
                          to="/point-setup"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/point-setup")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Point Setup
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(94) ? (
                        <Link
                          to="/privacy-policy"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/privacy-policy")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Privacy Policy
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(25) ? (

                        <Link
                          to="/responsibility"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/responsibility")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Responsibility
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(26) ? (
                        <Link
                          to="/legal-notice"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/legal-notice")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Legal Notice
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(27) ? (
                        <Link
                          to="/settings/social-media"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/social-media")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Social Media
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(28) ? (
                        <Link
                          to="/settings/customer-love"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/customer-love")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Customer Love
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}




                    </ul>
                  </Accordion.Body>

                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************  Customization End ************************************************************* */}

              {/* ************************************************************  Coupon Codes  ************************************************************* */}


              {/* ************************************************************  Customers End ************************************************************* */}

              {/* ************************************************************  Location ************************************************************* */}
              {isAllow?.includes(9) ||
                isAllow?.includes(13) ||
                isAllow?.includes(17) ||
                isAllow?.includes(21) ? (
                <Accordion.Item
                  eventKey="8"
                  onClick={() => { handleClick("8"); setSidebarOpen(true) }}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-map-location-dot"

                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Location
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className={`accordion_list list-unstyled fw-normal  small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                        }`}
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                    >
                      {IDS.Country.some((value) =>
                        isAllow.includes(value)
                      ) && (
                          <Link
                            to="/settings/location/country"
                            className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("/settings/location/country")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            >
                              <p
                                className="insideDropdown_list"
                                style={{ display: isOpen ? "block" : "none" }}
                              >
                                Country
                              </p>
                            </li>
                          </Link>)}
                      {/* {isAllow?.includes(13) ? ( */}
                      {IDS.State.some((value) =>
                        isAllow.includes(value)
                      ) && (
                          <Link
                            to="/settings/location/state"
                            className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("/settings/location/state")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            >
                              <p
                                className="insideDropdown_list"
                                style={{ display: isOpen ? "block" : "none" }}
                              >
                                State
                              </p>
                            </li>
                          </Link>)}
                      {/* ) : (
                      <></>
                    )} */}
                      {isAllow?.includes(17) ? (

                        <Link
                          to="/settings/location/city"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/location/city")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              City
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(21) ? (
                        <Link
                          to="/settings/location/pincode"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/location/pincode")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Pincode
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}




                    </ul>
                  </Accordion.Body>

                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************  Location End ************************************************************* */}

              {/* ************************************************************  Home Settings  ************************************************************* */}
              {isAllow?.includes(67) || isAllow?.includes(68) ? (
                <Accordion.Item
                  eventKey="9"
                  onClick={() => { handleClick("9"); setSidebarOpen(true) }}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-house-laptop"

                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Home Settings
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className={`accordion_list list-unstyled fw-normal  small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                        }`}
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                    >
                      {isAllow?.includes(67) ? (
                        <Link
                          to="/settings/customization/home-banner"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/customization/home-banner")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Home Banner
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(68) ? (
                        <Link
                          to="/settings/customization/home-ad-banner"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/customization/home-ad-banner")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Special Announcement
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}

                      {/* <Link
                        to="/settings/customization/category-banner"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/settings/customization/category-banner")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Category Banner
                          </p>
                        </li>
                      </Link> */}

                    </ul>
                  </Accordion.Body>

                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************   Home Settings End ************************************************************* */}

              {/* ************************************************************  Blogs  ************************************************************* */}
              {isAllow?.includes(66) || isAllow?.includes(65) ? (
                <Accordion.Item
                  eventKey="10"
                  onClick={() => { handleClick("10"); setSidebarOpen(true) }}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-blog"

                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Advertisement
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className={`accordion_list list-unstyled fw-normal  small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                        }`}
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                    >
                      {isAllow?.includes(65) ? (
                        <Link
                          to="/settings/customization/blogs"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/customization/blogs")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Blogs
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(66) ? (
                        <Link
                          to="/latest-news"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/latest-news")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Latest News
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}

                    </ul>
                  </Accordion.Body>

                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************   Blogs End ************************************************************* */}

              {/* ************************************************************  Brands  ************************************************************* */}
              {isAllow?.includes(49) || isAllow?.includes(50) ? (
                <Accordion.Item
                  eventKey="49"
                  onClick={() => { handleClick("49"); setSidebarOpen(true) }}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-copyright"

                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Brands
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className={`accordion_list list-unstyled fw-normal  small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                        }`}
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                    >
                      <Link
                        to="/settings/brands"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/settings/brands")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Brands
                          </p>
                        </li>
                      </Link>

                    </ul>
                  </Accordion.Body>

                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************   Brands End ************************************************************* */}

              {/* ************************************************************  Tax  ************************************************************* */}
              {isAllow?.includes(95) ||
                isAllow?.includes(96) ? (
                <Accordion.Item
                  eventKey="12"
                  onClick={() => { handleClick("12"); setSidebarOpen(true) }}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-money-check-dollar"

                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Talk to us
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className={`accordion_list list-unstyled fw-normal  small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                        }`}
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                    >
                      <Link
                        to="help-us"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/help-us")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                           Talk to us
                          </p>
                        </li>
                      </Link>

                      {/* <Link
                        to="/settings/tax/tax-percentage"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/settings/tax/tax-percentage")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Tax Percentage
                          </p>
                        </li>
                      </Link> */}

                    </ul>
                  </Accordion.Body>

                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************   Tax End ************************************************************* */}

              {/* ************************************************************  Attributes  ************************************************************* */}





              {/* ************************************************************   Attributes End ************************************************************* */}

              {/* ************************************************************  FAQ  ************************************************************* */}
              {isAllow?.includes(41) ||
                isAllow?.includes(45) ? (
                <Accordion.Item
                  eventKey="14"
                  onClick={() => { handleClick("14"); setSidebarOpen(true) }}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        // icon="fa-regular fa-circle-question"
                        icon="fa-solid fa-circle-question"

                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      FAQs
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className={`accordion_list list-unstyled fw-normal  small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                        }`}
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                    >
                      {isAllow?.includes(41) ? (
                        <Link
                          to="/settings/customization/faq-category"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/customization/faq-category")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Faq Category
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(45) ? (

                        <Link
                          to="/settings/customization/faqs"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/customization/faqs")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              FAQs
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}

                    </ul>
                  </Accordion.Body>

                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************   FAQ End ************************************************************* */}

              {/* ************************************************************  Country Codes  ************************************************************* */}

              {/* ************************************************************  Setting Ends ************************************************************* */}

              {/* ************************************************************  Employee Managment ************************************************************* */}
              {isAllow?.includes(1) ||
                isAllow?.includes(2) ||
                isAllow?.includes(3) ||
                isAllow?.includes(4) ?
                (
                  <Accordion.Item
                    eventKey="16"
                    onClick={() => { handleClick("16"); setSidebarOpen(true) }}
                  >
                    <Accordion.Header>
                      <div className="me-3">
                        <FontAwesomeIcon
                          icon="fa-solid fa-gear"
                        // onClick={() => setIsOpen(!isOpen)}
                        />
                      </div>
                      <p
                        className="sidebar_txt"
                        style={{ display: isSidebarOpen ? "block" : "none" }}
                      >
                        Employee Management
                      </p>
                    </Accordion.Header>
                    <Accordion.Body
                      className="p-0"
                      style={{
                        display: isOpen ? "block" : "none",
                      }}
                    >
                      <ul
                        className={`accordion_list list-unstyled fw-normal  small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                          }`}
                        style={{
                          display: isSidebarOpen ? "block" : "none",
                        }}
                      >
                        {isAllow?.includes(1) ||
                          isAllow?.includes(2) ||
                          isAllow?.includes(3) ||
                          isAllow?.includes(4) ? (
                          <Link
                            to="/employee/role"
                            className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("/employee/role")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            >
                              <p
                                className="insideDropdown_list"
                                style={{ display: isOpen ? "block" : "none" }}
                              >
                                Roles
                              </p>
                            </li>
                          </Link>
                        ) : (
                          <></>
                        )}


                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : (
                  <></>
                )}

              {/* ************************************************************   Employee Managment End ************************************************************* */}


              {isAllow?.includes(103) ||
                isAllow?.includes(104) ||
                isAllow?.includes(105) ||
                isAllow?.includes(106) ? (
                <Accordion.Item
                  eventKey="17"
                  onClick={() => { handleClick("17"); setSidebarOpen(true) }}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-ticket"
                      // onClick={() => setIsOpen(!isOpen)}
                      />

                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      About Us
                    </p>
                  </Accordion.Header>
                  <Accordion.Body className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className={`accordion_list list-unstyled fw-normal  small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                        }`}
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                    >
                      {isAllow?.includes(103) ? (
                        <Link
                          to="/about-us/who-we-are"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/about-us/who-we-are")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Who We Are
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(104) ? (
                        <Link
                          to="/about-us/values"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/about-us/values")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Values
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(105) ? (
                        <Link
                          to="/about-us/our-latest-award"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/about-us/our-latest-award")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Our Latest Award
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(106) ? (
                        <Link
                          to="/about-us-counter"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/about-us-counter")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              About Us Counter
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}

                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {/* ************************************************************  Products End ************************************************************* */}
              {isAllow?.includes(38) || isAllow?.includes(39) || isAllow?.includes(37) ? (
                <Accordion.Item
                  eventKey="50"
                  onClick={() => { handleClick("50"); setSidebarOpen(true) }}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-mattress-pillow"

                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Offers
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className={`accordion_list list-unstyled fw-normal  small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                        }`}
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                    >
                      {isAllow?.includes(37) ? (
                        <Link
                          to="/offer"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/offer")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Offers
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(38) ? (
                        <Link
                          to="/home-offer"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/home-offer")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Super Savings Offers
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}
                      {isAllow?.includes(49) ? (
                        <Link
                          to="/blog-offer"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/blog-offer")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Blog Offers
                            </p>
                          </li>
                        </Link>) : (
                        <></>
                      )}

                    </ul>
                  </Accordion.Body>

                </Accordion.Item>
              ) : (
                <></>
              )}

            </Accordion>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Sidebar;
