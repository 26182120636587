import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../common/ModelSave";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../common/Button";
import {
  Row,
  Col,
  Form,
  Modal,
  Container,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import JoditEditor from "jodit-react";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(fas);

const EditOffCanvance = (props) => {
  const {
    postData,
    getData,
    Select2Data,
    IMG_URL,
    getDimension,
    deleteData,
    isAllow,
  } = useContext(Context);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [childSubCategories, setChildSubCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [taxTypes, setTaxTypes] = useState([]);
  const [taxPercentages, setPercentages] = useState([]);
  const [attributes, setAttribute] = useState([]);
  const [subAttributes, setSubAttribute] = useState([]);
  const id = props.show;
  const [role, setRole] = useState([]);
  const [data, setData] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [occasion, setOccasion] = useState([]);
  const [material, setMaterial] = useState([]);
  const [clothsize, setClothSize] = useState([]);
  const [isClothSizeEnabled, setIsClothSizeEnabled] = useState(false);
  const [isCustomizeEnabled, setIsCustomizeEnabled] = useState(false);
  const [countries, setCountries] = useState([]);

  const [user, setUser] = useState({});
  const GetUserData = async () => {
    const response = await getData(`/usersingleget`);
    if (response?.success) {
      console.log("user :-", response?.data);
      setUser(response?.data);
    }
  };
  useEffect(() => {
    GetUserData();
  }, []);

  useEffect(() => {
    console.log("user?.id == ", user?.id);
    console.log("i am here ", user);
    if (user?.id === 1) {
      setIsDisabled(true);
    }
  }, [user]);

  console.log("isDisabled ", isDisabled);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm();
  const imagesFile = watch("images");
  const imageFile = watch("image1");
  // console.log("imagesFile:", imagesFile);

  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "variants", // Name of your field array
  // });

  const {
    fields: variantFields,
    append: appendVariant,
    remove: removeVariant,
    update: updateVariant,
  } = useFieldArray({
    control,
    name: "variants",
  });

  const [variantsError, setVariantsError] = useState("");

  useEffect(() => {
    register("variants", {
      validate: (value) => {
        const isValid = value && value.length > 0;
        setVariantsError(isValid ? "" : "At least one variant is required");
        return isValid;
      },
    });
  }, [register]);

  const validateSellingPrice = (value, index) => {
    const mrpValue = parseFloat(getValues(`variants.${index}.mrp`));
    const sellingPrice = parseFloat(value);
    if (sellingPrice >= mrpValue) {
      return "Selling price must be less than MRP";
    }
    return true; // Validation passed
  };

  const onSubmit = async (data) => {
    console.log(data, "data data data data kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");

    try {
      const DataToSend = new FormData();
      DataToSend.append("name", data?.name);
      DataToSend.append("hsn_code", data?.hsn_code);
      if (isCustomizeEnabled) {
        DataToSend.append("customize_price", data?.customize_price);
      }
      DataToSend.append("short_description", data?.short_description);
      DataToSend.append("manufacturer", data?.manufacturer);
      DataToSend.append("variants_name", data?.variants_name);
      DataToSend.append("vender", data?.vender);
      DataToSend.append("about", data?.about);
      DataToSend.append("benifits", data?.benifits);
      DataToSend.append("storage", data?.storage);
      DataToSend.append("image1", data?.image1[0]);
      DataToSend.append("category_id", data?.category_id?.value);
      DataToSend.append("sub_category_id", data?.sub_category_id?.value);
      // DataToSend.append(
      //   "child_sub_category_id",
      //   data?.child_sub_category_id?.value
      // );
      DataToSend.append("country_id", data?.country_id?.value);

      if(data?.brand_id?.value){
        DataToSend.append("brand_id", data?.brand_id?.value);
      }  
      // DataToSend.append("brand_id", data?.brand_id?.value);
      DataToSend.append("tax_type_id", data?.tax_type_id?.value);
      DataToSend.append("tax_percentage_id", data?.tax_percentage_id?.value);
      if(data.material_id){
        DataToSend.append(
          "material_id",
          JSON.stringify(data.material_id.map((option) => option.value))
        );
      }
      if(data.occasion_id){
        DataToSend.append(
          "occasion_id",
          JSON.stringify(data.occasion_id.map((option) => option.value))
        );
      }
      
      DataToSend.append("size_status", isClothSizeEnabled);
      // Append variants
      const variants = [];
      data.variants.forEach((variant, index) => {
        console.log(variant, "variant ");



        let variantData = {
          variant_id: variant?.variant_id,
          v_name: variant.v_name,
          unit: variant.unit,
          mrp: variant.mrp,
          sku: variant.sku,
          s_price: variant.s_price,
          quantity: variant.quantity?.map((qty) => ({
            unit: qty.unit,
            min: qty.min,
            max: qty.max,
          })),
        };


        // Conditionally add cloth_size_id if isClothSizeEnabled is true
        if (isClothSizeEnabled) {
          variantData.cloth_size_id = variant.cloth_size_id;
        }

        // Push the variant data to the variants array
        variants.push(variantData);

        let inputImages = document.getElementById(`variantImages${index}`);
        console.log(inputImages?.files);
        const files = inputImages?.files;
        if (files) {
          
          for (let i = 0; i < files?.length; i++) {
            DataToSend.append(`images${index}`, files[i]);
          }
        }
        // if (variant?.variant_images?.length > 0) {
        //   for (let i = 0; i < variant?.variant_images?.length - 1; i++) {
        //     if (variant?.variant_images[i]) {
        //       DataToSend.append(`path${index}`, variant?.variant_images[i].image)
        //     }
        //   }
        // }
      });

      DataToSend.append("variants", JSON.stringify(variants));
      const response = await postData(`/product/${id}`, DataToSend);
      console.log("response", response);
      console.log("data to send", DataToSend);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  
  
  const deleteImage = async (imageId) => {
    try {
      const response = await deleteData(`/product/product-image/${imageId}`);
      if (response.success) {
        console.log("Image deleted successfully");
      } else {
        console.error("Failed to delete image");
      }
      GetEditData();
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };
  const deleteLocalImage = async (index) => {
    const updatedImagesFile = [...imagesFile];
    updatedImagesFile.splice(index, 1);
    setValue("images", updatedImagesFile);
  };

  const GetAllCategory = async () => {
    const response = await getData("/allcategories");

    if (response?.success) {
      setCategories(await Select2Data(response?.data, "category_id"));
    }
  };

  const GetAllSubCategory = async (id) => {
    const response = await getData(`/allsubcategories/${id}`);

    if (response?.success) {
      setSubCategories(await Select2Data(response?.data, "sub_category_id"));
    }
  };

  const GetAllChildSubCategory = async (id) => {
    const response = await getData(`/allchildsubcategories/${id}`);

    if (response?.success) {
      setChildSubCategories(
        await Select2Data(response?.data, "child_sub_category_id")
      );
    }
  };

  const GetAllBrands = async () => {
    const response = await getData("/allbrands");

    if (response?.success) {
      setBrands(await Select2Data(response?.data, "brand_id"));
    }
  };

  const GetAllTaxTypes = async () => {
    const response = await getData("/all-tax-type");

    if (response?.success) {
      setTaxTypes(await Select2Data(response?.data, "tax_type_id"));
    }
  };

  const GetAllTaxPercentages = async (id) => {
    const response = await getData(`/all-tax-percentage/${id}`);

    if (response?.success) {
      setPercentages(await Select2Data(response?.data, "tax_percentage_id"));
    }
  };

  const GetAllAttributes = async () => {
    const response = await getData(`/all-attribute`);

    if (response?.success) {
      setAttribute(await Select2Data(response?.data, "attribute_id"));
    }
  };

  const GetAllSubAttributes = async (id) => {
    const response = await getData(`/all-sub-attribute/${id}`);

    if (response?.success) {
      setSubAttribute(await Select2Data(response?.data, "sub_attribute_id"));
    }
  };


 
  
  const GetEditData = async () => {
    const response = await getData(`/product/${id}`);
    console.log(response?.data, "response?.data");

    setData(response);
    setData(response?.data);
    reset(response?.data);
   
    
    console.log(response?.data?.country_id?.value,"response?.data?.country_id?.id");
    setValue("country_id", {
      value: response?.data?.country_id?.value,
      label: (
        <div>
          <img
            src={`${IMG_URL}${response?.data?.country_id?.image ?? ''}`}
            alt={response?.data?.country_id?.label ?? 'image'}
            style={{ width: '20px', height: '20px', marginRight: '8px' }}
          />
          {response?.data?.country_id?.label ?? ''}
        </div>
      ),
      name: response?.data?.country_id?.name,
    });
    if (response?.data?.customize_status) {
      setIsCustomizeEnabled(true);
    }
    if (response?.data?.variants) {
      const initialClothSizeEnabled = {};
      let hasClothSizeEnabled = false;

      response.data.variants.forEach((variant, index) => {
        initialClothSizeEnabled[variant.id] =
          variant.cloth_size_id && variant.cloth_size_id.length > 0;
        if (initialClothSizeEnabled[variant.id]) {
          hasClothSizeEnabled = true;
        }
      });

      if (response?.data?.size_status) {
        setIsClothSizeEnabled(true);
      }

      // console.log(initialClothSizeEnabled, "cloth size id ");
    }
  };

  const GetAllOccasion = async () => {
    const response = await getData("/allOccasion");

    if (response?.success) {
      setOccasion(await Select2Data(response?.data, "occasion_id"));
    }
  };

  const GetAllMaterial = async () => {
    const response = await getData("/allMaterial");

    if (response?.success) {
      setMaterial(await Select2Data(response?.data, "material_id"));
    }
  };

  const GetAllClothSize = async () => {
    const response = await getData("/allClothSize");

    if (response?.success) {
      setClothSize(await Select2Data(response?.data, "cloth_size_id"));
    }
  };


  useEffect(() => {
    GetEditData();
    GetAllCategory();
    GetAllBrands();
    GetAllTaxTypes();
    GetAllAttributes();
    GetAllMaterial();
    GetAllOccasion();
    GetAllClothSize();
  }, []);


  const handleAddQuantity = (variantIndex) => {
   

    const currentVariant = variantFields[variantIndex];

    const updatedQuantities = currentVariant.quantity
      ? [...currentVariant.quantity]
      : [];
    updatedQuantities.push({ min: "", max: "", unit: "" });

    // Ensure the `id` and other fields are preserved
    updateVariant(variantIndex, {
      ...currentVariant,
      quantity: updatedQuantities,
    });
  };

  const handleRemoveQuantity = (variantIndex, quantityIndex) => {
    // Get the current variant data
    const currentVariant = variantFields[variantIndex];

    // Create a copy of the current quantities
    const updatedQuantities = [...currentVariant.quantity];

    // Remove the quantity at the specified index
    updatedQuantities.splice(quantityIndex, 1);

    // Update only the quantity for the specific variant
    updateVariant(variantIndex, {
      ...currentVariant, // Preserve all other fields in the variant
      quantity: updatedQuantities,
    });
  };

  const handleAddVariant = () => {
    appendVariant({ quantity: [] }); // Add a variant with an empty quantity array
  };

  // Function to handle removing a variant
  const handleRemoveVariant = (index) => {
    removeVariant(index); // Remove a variant by index
  };

  const Select3Data = async (data, name, other = false) => {
    const result = data.map((item) => ({
      value: item?.id,
      label: (
        <div>
          <img
            src={`${IMG_URL}${item?.image ?? ''}`}
            alt={item?.name ?? 'image'}
            style={{ width: '20px', height: '20px', marginRight: '8px' }}
          />
          {item?.name ?? ''}
        </div>
      ),
      name: name,
    }));

    if (other) {
      result.push({ value: "0", label: "Other", name: name });
    }

    return result;
  };

  const GetAllCountry = async () => {
    const response = await getData("/allcountry");

    if (response?.success) {
      setCountries(await Select3Data(response?.data, "country_id"));
    }
  };

  useEffect(() => {
    GetAllCountry();
    // setCountries(Select2Data(Country))
  }, []);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit Employee</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>Edit Product</Card.Title>
                <hr />
                <Container>
                  <Form
                    // onSubmit={() => handleSubmit(onSubmit)}
                    role="form"
                  // className="stateclass"
                  >
                    <Row>
                    <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Country of Origin</Form.Label>
                            <Controller
                              name="country_id" // name of the field
                              {...register("country_id", {
                                required: "Select Country",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.country_id ? "red" : baseStyles.borderColor,
                                    }),
                                  }}
                                  {...field}
                                  options={countries}
                                  isSearchable={true} // Enables search
                                  filterOption={(candidate, input) =>
                                    candidate.label.props.children[1]
                                      ?.toString()
                                      ?.toLowerCase()
                                      ?.includes(input.toLowerCase())
                                  }
                                />
                              )}
                            />
                            {errors.country_id && (
                              <span className="text-danger">{errors.country_id.message}</span>
                            )}
                          </Row>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Category</Form.Label>
                            <Controller
                              name="category_id" // name of the field
                              {...register("category_id", {
                                required: "Select Category",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.category_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={categories}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value); // Update Controller's value
                                    GetAllSubCategory(selectedOption.value);
                                    setValue("category_id", selectedOption);
                                    setValue("sub_category_id", "");
                                    setValue("child_sub_category_id", "");
                                  }}
                                  isDisabled={isDisabled}
                                />
                              )}
                            />
                            {errors.category_id && (
                              <span className="text-danger">
                                {errors.category_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Sub Category</Form.Label>
                            <Controller
                              name="sub_category_id" // name of the field
                              {...register("sub_category_id", {
                                required: "Select Sub Category",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.sub_category_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={subCategories}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value); // Update Controller's value
                                    GetAllChildSubCategory(
                                      selectedOption.value
                                    );
                                    setValue("sub_category_id", selectedOption);
                                    setValue("child_sub_category_id", "");
                                  }}
                                  isDisabled={isDisabled}
                                />
                              )}
                            />
                            {errors.sub_category_id && (
                              <span className="text-danger">
                                {errors.sub_category_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>

                   
                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="justify-content-center">
                            <Form.Label>Product Name</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Name .."
                                  className={classNames("", {
                                    "is-invalid": errors?.name,
                                  })}
                                  {...register("name", {
                                    required: "Name is required",
                                  })}
                                  disabled={isDisabled}
                                />
                              </InputGroup>
                              {errors.name && (
                                <span className="text-danger">
                                  {errors.name.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="justify-content-center">
                            <Form.Label>Gifting Short Description</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="short_description"
                                  placeholder="Short Description"
                                  maxLength={40}
                                  className={classNames("", {
                                    "is-invalid": errors?.short_description,
                                  })}
                                  {...register("short_description", {
                                    // required: "Short Description is required",
                                  })}
                                  disabled={isDisabled}
                                />
                              </InputGroup>
                              {errors.short_description && (
                                <span className="text-danger">
                                  {errors.short_description.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="justify-content-center">
                            <Form.Label>HSN Code</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  min="0"
                                  type="text"
                                  name="hsn_code"
                                  placeholder="HSN Code"
                                  maxLength={40}
                                  className={classNames("", {
                                    "is-invalid": errors?.hsn_code,
                                  })}
                                  {...register("hsn_code", {
                                    required: "HSN Code is required",
                                  })}
                                  onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '+') {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </InputGroup>
                              {errors.hsn_code && (
                                <span className="text-danger">
                                  {errors.hsn_code.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      {!isDisabled && (
                        <Col md={3}>
                          <div className="main-form-section my-3">
                            <Form.Label>Image</Form.Label>

                            <Form.Group>
                              <Form.Control
                                className={classNames("", {
                                  "is-invalid": errors?.image1,
                                })}
                                type="file"
                                multiple
                                {...register("image1", {
                                  // required: "images is required",
                                })}
                                accept="image/*"
                              />
                            </Form.Group>
                            {errors.image1 && (
                              <span className="text-danger">
                                {errors.image1.message}
                              </span>
                            )}
                          </div>
                        </Col>
                      )}

                      <Col lg={3}>
                        <div className="main-form-section my-3">
                          <Form.Label>Image Preview</Form.Label>

                          {typeof getValues("image1") == "string" ? (
                            <div className="image-preview-container">
                              <img
                                src={IMG_URL + getValues("image1")}
                                alt="Preview"
                                className="image-preview"
                                style={{ width: "150px", height: "130px" }}
                              />
                            </div>
                          ) : (
                            imageFile &&
                            imageFile?.length > 0 && (
                              <div className="image-preview-container">
                                <img
                                  // src={URL.createObjectURL(getValues("image")[0])}
                                  src={URL?.createObjectURL(imageFile[0])}
                                  alt="Preview"
                                  className="image-preview"
                                  style={{ width: "100px", height: "100px" }}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </Col>

                      <Col md={3}>
                        {/* <Row className="row justify-content-center mb-2"> */}
                        <Form.Check
                          disabled={isDisabled}
                          type="checkbox"
                          label="Customise Status"
                          checked={isCustomizeEnabled}
                          onChange={(e) =>
                            setIsCustomizeEnabled(e.target.checked)
                          }
                        />
                        {isCustomizeEnabled && (
                          <div className="main-form-section my-3">
                            <Row className="justify-content-center">
                              <Form.Label>Customise Price</Form.Label>
                              <Form.Group>
                                <InputGroup>
                                  <Form.Control
                                    disabled={isDisabled}
                                    type="text"
                                    name="customize_price"
                                    placeholder="customize price"
                                    maxLength={40}
                                    className={classNames("", {
                                      "is-invalid": errors?.customize_price,
                                    })}
                                    {...register("customize_price", {
                                      required: "customise price is required",
                                    })}
                                  />
                                </InputGroup>
                                {errors.customize_price && (
                                  <span className="text-danger">
                                    {errors.customize_price.message}
                                  </span>
                                )}
                              </Form.Group>
                            </Row>
                          </div>
                        )}
                        {/* </Row> */}
                      </Col>
                      {/* <Col md={3}>
                          {isCustomizeEnabled && (                         
                              <div className="main-form-section my-3">
                                <Row className="justify-content-center">
                                  <Form.Label>customize price</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        name="customize_price"
                                        placeholder="customize price"
                                        maxLength={40}
                                        className={classNames("", {
                                          "is-invalid": errors?.customize_price,
                                        })}
                                        {...register("customize_price", {
                                          required: "customize price is required",
                                        })}
                                      />
                                    </InputGroup>
                                    {errors.customize_price && (
                                      <span className="text-danger">
                                        {errors.customize_price.message}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Row>
                              </div>                        
                          )}
                       
                      </Col> */}

                      <div className="main-form-section mt-5"></div>
                      <Card.Title>Product Details</Card.Title>
                      <hr />

                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="justify-content-center">
                            <Form.Label>Manufacturer</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  disabled={isDisabled}
                                  type="text"
                                  name="manufacturer"
                                  placeholder="Manufacturer"
                                  className={classNames("", {
                                    "is-invalid": errors?.manufacturer,
                                  })}
                                  {...register("manufacturer", {
                                    required: "manufacturer is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.manufacturer && (
                                <span className="text-danger">
                                  {errors.manufacturer.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>

                          <Row className="justify-content-center">
                            <Form.Label>Vendor</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  disabled={isDisabled}
                                  type="text"
                                  name="vender"
                                  placeholder="vender"
                                  className={classNames("", {
                                    "is-invalid": errors?.vender,
                                  })}
                                  {...register("vender", {
                                    // required: "vender is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.vender && (
                                <span className="text-danger">
                                  {errors.vender.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Brand</Form.Label>
                            <Controller
                              name="brand_id" // name of the field
                              {...register("brand_id", {
                                // required: "Select Brand",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  isDisabled={isDisabled}
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.brand_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={brands}
                                />
                              )}
                            />
                            {errors.brand_id && (
                              <span className="text-danger">
                                {errors.brand_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Occasion</Form.Label>
                            <Controller
                              name="occasion_id" // name of the field
                              {...register("occasion_id", {
                                // required: "Select Occasion",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  isDisabled={isDisabled}
                                  isMulti
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.occasion_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={occasion}
                                />
                              )}
                            />
                            {errors.occasion_id && (
                              <span className="text-danger">
                                {errors.occasion_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Material</Form.Label>
                            <Controller
                              name="material_id" // name of the field
                              {...register("material_id", {
                                // required: "Select Material",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  isDisabled={isDisabled}
                                  isMulti
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.material_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={material}
                                />
                              )}
                            />
                            {errors.material_id && (
                              <span className="text-danger">
                                {errors.material_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Tax Type</Form.Label>
                            <Controller
                              name="tax_type_id" // name of the field
                              {...register("tax_type_id", {
                                required: "Select Tax Type",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  isDisabled={isDisabled}
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.tax_type_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={taxTypes}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value); // Update Controller's value
                                    GetAllTaxPercentages(selectedOption.value);
                                    setValue("tax_type_id", selectedOption);
                                  }}
                                />
                              )}
                            />
                            {errors.tax_type_id && (
                              <span className="text-danger">
                                {errors.tax_type_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section my-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Tax Percentage</Form.Label>
                            <Controller
                              name="tax_percentage_id" // name of the field
                              {...register("tax_percentage_id", {
                                required: "Select Tax Percentage",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  isDisabled={isDisabled}
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.tax_percentage_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={taxPercentages}
                                />
                              )}
                            />
                            {errors.tax_percentage_id && (
                              <span className="text-danger">
                                {errors.tax_percentage_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="main-form-section my-3">
                          <Row className="justify-content-start">
                            {" "}
                            {/* Align to left */}
                            {/* Description */}
                            <Col sm={12}>
                              <Form.Label className="text-center">
                              Product Description
                              </Form.Label>
                              <Form.Group>
                                <Controller
                                  name="about" // Provide the field name
                                  control={control} // Pass the control object from useForm()
                                  // rules={{ required: "about is required" }} // Validation rules
                                  render={({ field }) => (
                                    <JoditEditor
                                      value={field.value}
                                      onChange={(newContent) =>
                                        field.onChange(newContent)
                                      }
                                      onBlur={field.onBlur}
                                      className={classNames("", {
                                        "is-invalid": !!errors.about,
                                      })}
                                      placeholder="about"
                                      config={{
                                        readonly: isDisabled, // Set the editor to read-only mode based on isDisabled value
                                        toolbar: !isDisabled, // Show the toolbar if isDisabled is false
                                      }}
                                    />
                                  )}
                                />
                              </Form.Group>
                              {errors.about && (
                                <span className="text-danger">
                                  {errors.about.message}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="main-form-section my-3">
                          <Row className="justify-content-start">
                            {" "}
                            {/* Align to left */}
                            {/* Description */}
                            <Col sm={12}>
                              <Form.Label className="text-center">
                              Product Care
                              </Form.Label>
                              <Form.Group>
                                <Controller
                                  name="benifits"
                                  control={control}
                                  // rules={{ required: "benifits is required" }}
                                  render={({ field }) => (
                                    <JoditEditor
                                      value={field.value}
                                      onChange={(newContent) =>
                                        field.onChange(newContent)
                                      }
                                      onBlur={field.onBlur}
                                      className={classNames("", {
                                        "is-invalid": !!errors.benifits,
                                      })}
                                      placeholder="benifits"
                                      config={{
                                        readonly: isDisabled, // Set the editor to read-only mode based on isDisabled value
                                        toolbar: !isDisabled, // Show the toolbar if isDisabled is false
                                      }}
                                    />
                                  )}
                                />
                              </Form.Group>
                              {errors.benifits && (
                                <span className="text-danger">
                                  {errors.benifits.message}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="main-form-section my-3">
                          <Row className="justify-content-start">
                            {" "}
                            {/* Align to left */}
                            {/* Description */}
                            <Col sm={12}>
                              <Form.Label className="text-center">
                              Delivery & Returns
                              </Form.Label>
                              <Form.Group>
                                <Controller
                                  name="storage" // Provide the field name
                                  control={control} // Pass the control object from useForm()
                                  // rules={{ required: "storage is required" }} // Validation rules
                                  render={({ field }) => (
                                    <JoditEditor
                                      value={field.value}
                                      onChange={(newContent) =>
                                        field.onChange(newContent)
                                      }
                                      onBlur={field.onBlur}
                                      className={classNames("", {
                                        "is-invalid": !!errors.storage,
                                      })}
                                      placeholder="storage"
                                      config={{
                                        readonly: isDisabled, // Set the editor to read-only mode based on isDisabled value
                                        toolbar: !isDisabled, // Show the toolbar if isDisabled is false
                                      }}
                                    />
                                  )}
                                />
                              </Form.Group>
                              {errors.storage && (
                                <span className="text-danger">
                                  {errors.storage.message}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <div className="main-form-section mt-5"></div>
                      <Col md={4}>
                        <div className="main-form-section my-3">
                          <Row className="justify-content-center">
                            <Form.Label>Variants Name(eg.Colors,Coins,Glass)</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                disabled={isDisabled}
                                  type="text"
                                  name="variants_name"
                                  placeholder="Variants Name .."
                                  className={classNames("", {
                                    "is-invalid": errors?.variants_name,
                                  })}
                                  {...register("variants_name", {
                                    required: "Variants Name is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.variants_name && (
                                <span className="text-danger">
                                  {errors.variants_name.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>
                      <Card.Title>
                        Product variants Details
                        {variantsError && (
                          <div className="text-danger">{variantsError}</div>
                        )}
                      </Card.Title>
                      <hr />

                      {variantFields.map((variant, index) => (
                        <div
                          key={variant.id}
                          className="main-form-section my-3"
                        >
                          <Row>
                            {variant.quantity.map((quantity, quantityIndex) => (
                              <div
                                key={quantity.id}
                                className="main-form-section my-3"
                              >
                                <Row>
                                  <Col md={3}>
                                    <Form.Label>Min Quantity</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          min="0"
                                          disabled={isDisabled}
                                          type="number"
                                          name={`variants.${index}.quantity.${quantityIndex}.min`}
                                          placeholder="Min Quantity"
                                          className={classNames("", {
                                            "is-invalid":
                                              errors?.variants?.[index]
                                                ?.quantity?.[quantityIndex]
                                                ?.min,
                                          })}
                                          {...register(
                                            `variants.${index}.quantity.${quantityIndex}.min`,
                                            {
                                              required:
                                                "Min Quantity is required",
                                            }
                                          )}
                                          onKeyDown={(e) => {
                                            if (
                                              e.key === "-" ||
                                              e.key === "e" ||
                                              e.key === "+"
                                            ) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                      </InputGroup>
                                      {errors?.variants?.[index]?.quantity?.[
                                        quantityIndex
                                      ]?.min && (
                                          <span className="text-danger">
                                            {
                                              errors?.variants?.[index]
                                                ?.quantity?.[quantityIndex]?.min
                                                .message
                                            }
                                          </span>
                                        )}
                                    </Form.Group>
                                  </Col>

                                  <Col md={3}>
                                    <Form.Label>Max Quantity</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          min="0"
                                          disabled={isDisabled}
                                          type="number"
                                          name={`variants.${index}.quantity.${quantityIndex}.max`}
                                          placeholder="Max Quantity"
                                          className={classNames("", {
                                            "is-invalid":
                                              errors?.variants?.[index]
                                                ?.quantity?.[quantityIndex]
                                                ?.max,
                                          })}
                                          {...register(
                                            `variants.${index}.quantity.${quantityIndex}.max`,
                                            {
                                              required:
                                                "Max Quantity is required",
                                            }
                                          )}
                                          onKeyDown={(e) => {
                                            if (
                                              e.key === "-" ||
                                              e.key === "e" ||
                                              e.key === "+"
                                            ) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                      </InputGroup>
                                      {errors?.variants?.[index]?.quantity?.[
                                        quantityIndex
                                      ]?.max && (
                                          <span className="text-danger">
                                            {
                                              errors?.variants?.[index]
                                                ?.quantity?.[quantityIndex]?.max
                                                .message
                                            }
                                          </span>
                                        )}
                                    </Form.Group>
                                  </Col>

                                  <Col md={3}>
                                    <Form.Label>Price Per Unit</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          min="0"
                                          disabled={isDisabled}
                                          type="number"
                                          name={`variants.${index}.quantity.${quantityIndex}.unit`}
                                          placeholder="Price Per Unit"
                                          className={classNames("", {
                                            "is-invalid":
                                              errors?.variants?.[index]
                                                ?.quantity?.[quantityIndex]
                                                ?.unit,
                                          })}
                                          {...register(
                                            `variants.${index}.quantity.${quantityIndex}.unit`,
                                            {
                                              required:
                                                "Price Per Unit is required",
                                            }
                                          )}
                                          onKeyDown={(e) => {
                                            if (
                                              e.key === "-" ||
                                              e.key === "e" ||
                                              e.key === "+"
                                            ) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                      </InputGroup>
                                      {errors?.variants?.[index]?.quantity?.[
                                        quantityIndex
                                      ]?.unit && (
                                          <span className="text-danger">
                                            {
                                              errors?.variants?.[index]
                                                ?.quantity?.[quantityIndex]?.unit
                                                .message
                                            }
                                          </span>
                                        )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                {!isDisabled && (
                                  <button
                                    className="add-varient mt-3"
                                    type="button"
                                    onClick={() =>
                                      handleRemoveQuantity(index, quantityIndex)
                                    }
                                  >
                                    Remove Quantity
                                  </button>
                                )}
                              </div>
                            ))}

                            <div className="text-center mt-3">
                              {!isDisabled && (
                                <button
                                  type="button"
                                  className="add-varient mt-3"
                                  onClick={() => handleAddQuantity(index)}
                                >
                                  + Add Quantity
                                </button>
                              )}
                            </div>

                            <Col md={3}>
                              <div className="main-form-section my-3">
                                <Row className="justify-content-center">
                                  <Form.Label>
                                    Variant Name
                                  </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        disabled={isDisabled}
                                        type="text"
                                        name={`variants.${index}.v_name`} // Register color for the variant
                                        placeholder="Variant Name"
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.variants?.[index]?.v_name,
                                        })}
                                        {...register(
                                          `variants.${index}.v_name`,
                                          {
                                            required:
                                              "Variant name is required",
                                          }
                                        )}
                                      />
                                    </InputGroup>

                                    {errors?.variants?.[index]?.v_name && (
                                      <span className="text-danger">
                                        {errors.variants[index].v_name.message}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section my-3">
                                <Row className="justify-content-center">
                                  <Form.Label>Stock Unit</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        disabled={isDisabled}
                                        type="number"
                                        name={`variants.${index}.unit`}
                                        placeholder="Stock Unit"
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.variants?.[index]?.unit, // Updated error handling
                                        })}
                                        {...register(`variants.${index}.unit`, {
                                          required: "Stock Unit is required",
                                        })}
                                      />
                                    </InputGroup>
                                    {errors?.variants?.[index]?.unit && (
                                      <span className="text-danger">
                                        {
                                          errors?.variants?.[index]?.unit
                                            .message
                                        }
                                      </span>
                                    )}
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            {/* MRP */}
                            <Col md={3}>
                              <div className="main-form-section my-3">
                                <Row className="justify-content-center">
                                  <Form.Label>MRP</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        // disabled={isDisabled}
                                        type="number"
                                        name={`variants.${index}.mrp`}
                                        placeholder="mrp"
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.variants?.[index]?.mrp, // Updated error handling
                                        })}
                                        {...register(`variants.${index}.mrp`, {
                                          required: "MRP is required",
                                        })}
                                      />
                                    </InputGroup>
                                    {errors?.variants?.[index]?.mrp && (
                                      <span className="text-danger">
                                        {errors.variants[index].mrp.message}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            {/* SKU */}
                            <Col md={3}>
                              <div className="main-form-section my-3">
                                <Row className="justify-content-center">
                                  <Form.Label>SKU</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        disabled={isDisabled}
                                        type="text"
                                        name={`variants.${index}.sku`}
                                        placeholder="sku"
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.variants?.[index]?.sku, // Updated error handling
                                        })}
                                        {...register(`variants.${index}.sku`, {
                                          required: "sku is required",
                                        })}
                                      />
                                    </InputGroup>
                                    {errors?.variants?.[index]?.sku && (
                                      <span className="text-danger">
                                        {errors.variants[index].sku.message}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            {/* Selling Prise */}
                            <Col md={3}>
                              <div className="main-form-section my-3">
                                <Row className="justify-content-center">
                                  <Form.Label>Selling Price</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        // disabled={isDisabled}
                                        type="number"
                                        name={`variants.${index}.s_price`}
                                        placeholder="Selling Price"
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.variants?.[index]?.s_price, // Updated error handling
                                        })}
                                        {...register(
                                          `variants.${index}.s_price`,
                                          {
                                            required:
                                              "Selling Price is required",
                                            validate: (value) => {
                                              if (!value)
                                                return "Selling Price is required";
                                              const minimumOrderValue =
                                                parseFloat(
                                                  getValues(
                                                    `variants.${index}.mrp`
                                                  )
                                                );
                                              if (parseFloat(value) <= 0) {
                                                return "Selling Price must be a positive number";
                                              }

                                              // Check if discount is less than minimum order value
                                              if (
                                                parseFloat(value) >
                                                minimumOrderValue
                                              ) {
                                                return "Selling Price must not be greater than MRP";
                                              }

                                              return true; // Return true for valid input
                                            },
                                          }
                                        )}
                                      />
                                    </InputGroup>
                                    {errors?.variants?.[index]?.s_price && (
                                      <span className="text-danger">
                                        {errors.variants[index].s_price.message}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>



                            <Col lg={6}>
                              {/* <Col lg={6}> */}
                              <div className="main-form-section my-3">
                                <Form.Label>Images(Image Should be in 1080 * 1080 dimension)</Form.Label>
                                {!isDisabled && (
                                  <Form.Group>
                                    <Form.Control
                                      className={classNames("", {
                                        "is-invalid": errors?.image,
                                      })}
                                      type="file"
                                      multiple
                                      {...register(
                                        `variants.${index}.image`,
                                        {
                                          required: getValues(
                                            `variants.${index}.variant_id`
                                          )
                                            ? ""
                                            : "Images are required for new variant",
                                        }
                                      )}
                                      id={`variantImages${index}`}
                                      accept="image/*"
                                    />
                                  </Form.Group>
                                )}
                                {errors.variants?.[index]?.image && (
                                  <span className="text-danger">
                                    {errors.variants?.[index]?.image.message}
                                  </span>
                                )}

                                <Table striped bordered hover>
                                  <thead>
                                    <tr>
                                      <th>Preview</th>
                                      {!isDisabled && <th>Action</th>}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {variantFields[
                                      index
                                    ]?.variant_images?.map(
                                      (image, imageIndex) => (
                                        <tr key={imageIndex}>
                                          <td>
                                            <img
                                              src={IMG_URL + image.image}
                                              alt={`Image ${imageIndex + 1}`}
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                              }}
                                            />
                                          </td>
                                          {!isDisabled && (
                                            <td style={{ width: "50px" }}>
                                              <Button
                                                variant="danger"
                                                onClick={() =>
                                                  deleteImage(image?.id)
                                                }
                                                style={{
                                                  width: "100%",
                                                  padding: "0.375rem 0.75rem",
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={["fas", "trash"]}
                                                />
                                              </Button>
                                            </td>
                                          )}
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                              {/* </Col> */}
                            </Col>


                            <Col md={6}>
                              <Form.Check
                                type="checkbox"
                                label="Cloth Size (Not Require)"
                                disabled={isDisabled}
                                checked={isClothSizeEnabled}
                                onChange={(e) =>
                                  setIsClothSizeEnabled(e.target.checked)
                                }
                              />
                              <div className="main-form-section my-3">
                                {isClothSizeEnabled && (
                                  // <Row className="row justify-content-center mb-2">
                                  <>
                                    <Form.Label>Size(Optional)</Form.Label>
                                    <Controller
                                      name={`variants.${index}.cloth_size_id`}
                                      control={control}
                                      render={({ field }) => (
                                        <Select
                                          isDisabled={isDisabled}
                                          isMulti
                                          styles={{
                                            control: (baseStyles) => ({
                                              ...baseStyles,
                                              borderColor: errors?.variants?.[
                                                index
                                              ]?.cloth_size_id
                                                ? "red"
                                                : baseStyles.borderColor,
                                            }),
                                          }}
                                          {...field}
                                          options={clothsize}
                                        />
                                      )}
                                      rules={{ required: "Select cloth size" }}
                                    />
                                    {errors?.variants?.[index]
                                      ?.cloth_size_id && (
                                        <span className="text-danger">
                                          {
                                            errors?.variants?.[index]
                                              ?.cloth_size_id.message
                                          }
                                        </span>
                                      )}
                                  </>
                                  // </Row>
                                )}
                              </div>
                            </Col>
                          </Row>
                          {/* {!isDisabled && (<button type="button" className="add-varient" onClick={() => remove(index)}>
                            Remove Variant</button>)} */}
                          {!isDisabled && (
                            <button
                              className="add-varient"
                              type="button"
                              onClick={() => handleRemoveVariant(index)}
                            >
                              Remove Variant
                            </button>
                          )}
                        </div>
                      ))}
                      <div className="main-form-section my-3"></div>
                      <hr />
                      {/* {!isDisabled && (
                        <div className="text-center">
                          <button type="button" className="add-varient" onClick={() => append({})} >+ Add Variant</button>
                        </div>
                      )} */}
                      {!isDisabled && (
                        <div className="text-center">
                          <button
                            type="button"
                            className="add-varient"
                            onClick={() => handleAddVariant()}
                          >
                            + Add Variant
                          </button>
                        </div>
                      )}
                      <Row className="mt-5 pb-3">
                        {/* {!isDisabled && ( */}
                          <div className="d-flex justify-content-center">
                            <Link>
                              <CancelButton
                                name={"cancel"}
                                handleClose={props.handleClose}
                              />
                            </Link>

                            <Button
                              name={"save"}
                              onClick={handleSubmit(onSubmit)}
                              type="button"
                              className="save-btnnnnnn"
                            >
                              <FontAwesomeIcon
                                icon="fa-solid fa-floppy-disk"
                                className="me-2"
                              />
                              save
                            </Button>
                          </div>
                        {/* )} */}
                        {/* {isDisabled && (
                          <div className="d-flex justify-content-center">
                            <Link>
                              <CancelButton
                                name={"Back"}
                                handleClose={props.handleClose}
                              />
                            </Link>
                          </div>
                         )}  */}
                      </Row>
                    </Row>
                  </Form>
                </Container>
              </Card.Body>
            </Card>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
