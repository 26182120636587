import React, { useContext, useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./AdvanceDashboard.css";
import Header from "../../Header/Header";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "react-circular-progressbar/dist/styles.css";
import "swiper/swiper-bundle.css";
import CanvasJSReact from "@canvasjs/react-charts";
import { Context } from "../../../utils/context";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "react-bootstrap/Accordion";
import { faCartShopping, faBoxOpen, faUsers, faStore } from "@fortawesome/free-solid-svg-icons";
import { Chart } from "react-google-charts";
import DataTable from 'datatables.net';
import Button from "react-bootstrap/Button";
import { Link, useLocation } from "react-router-dom";
import { Table } from "react-bootstrap";
import Cookies from "js-cookie";
import search1 from "../../../Components/assets/icons/search.png";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

library.add(fas);

const AdvanceDashboard = () => {
    const { getData, userdata, user } = useContext(Context);
    const [result, setResult] = useState({});
    const getDataAll = async () => {
        const result = await getData("/dashboard");
        setResult(result.data);
    };
    // console.log(result);
    useEffect(() => {
        // getDataAll();
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');
        if (token) {
            Cookies.set("coinzkart_admin_security", token, {
                expires: 1,
            });
        }

    }, []);


    // search functionality start

    const [filter, setFilter] = useState("");

    const handleFilterChange = (event) => {
        setFilter(event.target.value.toLowerCase());
    };

    const [countOrders, setCountOrders] = useState("");
    const GetEditData = async () => {
        const res = await getData(`/count-all-orders`);
        setCountOrders(res?.data?.count);
    };
    const [countProducts, setCountProducts] = useState("");
    const GetProductData = async () => {
        const res = await getData(`/count-all-products`);
        setCountProducts(res?.data?.count);
    };
    const [countCustomers, setCountCustomers] = useState("");
    const GetCustomersData = async () => {
        const res = await getData(`/count-all-customers`);
        setCountCustomers(res?.data?.count);
    };
    const [countSellers, setCountSellers] = useState("");
    const GetSellersData = async () => {
        const res = await getData(`/count-all-customers`);
        setCountSellers(res?.data?.count);
    };
    useEffect(() => {
        GetEditData();
        GetProductData();
        GetCustomersData();
        GetSellersData();
    }, []);

    const cardsData = [
        {
            number: countOrders,
            types: "Orders",
            icon: "fa-cart-shopping",
            className: "#63bff5",
            api: '/order-managements/order'
        },
        {
            number: countProducts,
            types: "Products",
            icon: "fa-solid fa-boxes-stacked",
            className: "#63bff5",
            api: '/product'
        },
        {
            number: countCustomers,
            types: "Customers",
            icon: "fa-solid fa-users",
            className: "#63bff5",
            api: '/employee/customer-details'
        },
        {
            number: countSellers,
            types: "Sellers",
            icon: "fa-solid fa-users",
            className: "#63bff5",
            api: '/seller-detail'
        },
    ];

    const cardsSellerData = [
        {
            number: countOrders,
            types: "Orders",
            icon: "fa-cart-shopping",
            className: "#63bff5",
            api: '/order-managements/order'
        },
        {
            number: countProducts,
            types: "Products",
            icon: "fa-solid fa-boxes-stacked",
            className: "#63bff5",
            api: '/product'
        },

    ];
    const options = {
        data: [
            {
                color: "#4285f4",
                width: 100,
                dataPoints: [
                    { label: "29-March", y: 10 },
                    { label: "28-March", y: 15 },
                    { label: "20-March", y: 2 },
                    { label: "18-March", y: 30 },
                    { label: "13-March", y: 18 },
                    { label: "27-March", y: 10 },
                    { label: "30-March", y: 20 },
                ],
            },
        ],
    };

    // pie chart
    const dataOne = [
        ["Task", "Hours per Day"],
        ["Work", 11],
        ["Eat", 2],
        ["Commute", 2],
        ["Watch TV", 2],
        ["Sleep", 7],
    ];

    const optiontwo = {
        title: "Product Category Count",
        is3D: true,
    };

    // close button
    const [showBox, setShowBox] = useState(true);

    const handleClose = () => {
        setShowBox(false);
    };


    useEffect(() => {
        const table = new DataTable('#example');
        return () => {
            table.destroy();
        };
    }, []);

    useEffect(() => {
        const table = new DataTable('#exampleContent');
        return () => {
            table.destroy();
        };
    }, []);


    const datanew = [
        { Name: "Tiger Nixon", Position: "System Architect", Office: "Edinburgh", Age: 61, StartDate: "2011-04-25", Salary: "$320,800" },
        { Name: "Garrett Winters", Position: "Accountant", Office: "Tokyo", Age: 63, StartDate: "2011-07-25", Salary: "$170,750" },
    ];

    const datacontent = [
        { Name: "Tiger Nixon", Position: "System Architect", Office: "Edinburgh", Age: 61, StartDate: "2011-04-25", Salary: "$320,800" },
        { Name: "Garrett Winters", Position: "Accountant", Office: "Tokyo", Age: 63, StartDate: "2011-07-25", Salary: "$170,750" },
    ];

    const colors = ['#63bff5', '#f39c12', '#dd4b39', '#00a65a'];
    const [search, setSearch] = useState("");
    const [reset, setReset] = useState();
    const [singleVarient, setsingleVarient] = useState("");
    const [inventoryProduct, setInventoryProduct] = useState("");
    const GetInventoryProduct = async () => {
        const res = await getData(
            `/all-stock-product?term=${search}&singleVarient=${singleVarient?.value || ""
            }`
        );
        await setInventoryProduct(res);
        setSearch(res?.search_name);
    };
    useEffect(() => {
        GetInventoryProduct();
    }, [reset]);

    console.log("cardsData",cardsData)

    return (
        <div className="main-advancedashboard">
            <section className="AdvanceDashboard">
                <div className="AdvanceDashboardTabs">
                    <Header title={"Dashboard"} link={"/masters/social_links"} />
                    <div className=""></div>
                </div>
            </section>
            <div className="Main-Section-Advanced-Dashboard">
                <h1 className="title">Home</h1>
                <Row>
                    {user?.id === 1 ? (
                        cardsData.map((item, index) => (
                            <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12} key={index}>
                                <Card className="small-box" style={{ backgroundColor: colors[index % colors.length] }}>
                                    <div className={item.className}>
                                        <div className="content">
                                            <div className="inner">
                                                <h3>{item.number}</h3>
                                                <p>{item.types}</p>
                                            </div>
                                            <div className="icon">
                                                <FontAwesomeIcon icon={item.icon} />
                                            </div>
                                        </div>
                                        <Link to={item?.api}>
                                            <p className="mb-0 small-box-footer">
                                                More info <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                                            </p>
                                        </Link>
                                    </div>
                                </Card>
                            </Col>
                        ))
                    ) : (
                        cardsSellerData.map((item, index) => (
                            <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12} key={index}>
                                <Card className="small-box" style={{ backgroundColor: colors[index % colors.length] }}>
                                    <div className={item.className}>
                                        <div className="content">
                                            <div className="inner">
                                                <h3>{item.number}</h3>
                                                <p>{item.types}</p>
                                            </div>
                                            <div className="icon">
                                                <FontAwesomeIcon icon={item.icon} />
                                            </div>
                                        </div>
                                        <Link to={item?.api}>
                                            <p className="mb-0 small-box-footer">
                                                More info <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                                            </p>
                                        </Link>
                                    </div>
                                </Card>
                            </Col>
                        ))
                    )}


                </Row>
                <div className="row me-0 ms-0 mt- data-table-holder">
                    <div className="col-md-12">
                        <h5>
                            <b>Stock Inventory</b>
                        </h5>
                        <div className="search-holder">
                            <div className="row">

                                <div className="col-xxl-3 col-xl-2  col-lg-3  col-md-6 col-12 mt-md-0 mt-3 mb-3">
                                    <div className="num me-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id=""
                                            placeholder="product"
                                            value={search}
                                            onChange={(e) => {
                                                setSearch(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-xxl-1 col-xl-2  col-lg-2  col-md-6 col-12 mt-md-0 mt-3 mb-3">
                                    <div className="Search me-2">
                                        <Button
                                            type="button"
                                            onClick={GetInventoryProduct}
                                            className="btn btn-search"
                                        >
                                            Search
                                        </Button>
                                    </div>
                                </div>
                                <div className="col-xxl-1 col-xl-2  col-lg-2  col-md-6 col-12 mt-md-0 mt-3 mb-3">
                                    <div className="Search-1 me-2">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setSearch("");
                                                setsingleVarient("");
                                                setReset(!reset);
                                            }}
                                            className="btn btn-reset btn-danger"
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-xxl-3 col-xl-3  col-lg-4  col-md-5 col-12 mt-md-0 mt-3">
              <div className="num ">
                <Select
                  isSearchable
                  options={categories}
                  value={singleVarient}
                  placeholder="Select Category"
                  onChange={(e) => {
                    setsingleVarient(e);
                  }}
                />
              </div>
            </div> */}

                        <br />

                        <div className="data table-responsive">
                            <Table striped bordered hover responsive center>
                                <thead>
                                    <tr>
                                        <th className="sr">Sr. No.</th>
                                        <th className="tax-name">Product</th>
                                        <th className="tax-name">Variant</th>
                                        <th className="tax-name">Unit</th>
                                        <th className="tax-name">Stock Limit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inventoryProduct?.data?.map((d, index) => (
                                        <React.Fragment key={index}>
                                            {d?.product_variants?.map((variant, variantIndex) => (
                                                <tr key={variant.id}>
                                                    {/* Only show product name in the first row of each product */}
                                                    {variantIndex === 0 ? (
                                                        <td rowSpan={d?.product_variants?.length}>{index + 1}.</td>
                                                    ) : null}
                                                    {variantIndex === 0 ? (
                                                        <td rowSpan={d?.product_variants?.length}>{d?.name}</td>
                                                    ) : null}
                                                    <td>{variant?.v_name}</td>
                                                    <td>{variant?.unit}</td>

                                                    {/* Conditional styling for stock limit */}
                                                    <td
                                                        style={{
                                                            color: variant?.unit <= 0 ? 'red' : variant?.unit < 10 ? 'orange' : 'black'
                                                        }}
                                                    >
                                                        {variant?.unit <= 0
                                                            ? 'Out of stock'
                                                            : variant?.unit < 10
                                                                ? 'Low in stock'
                                                                : 'In stock'}
                                                    </td>
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>
                        </div>


                    </div>
                </div>
                {/* <Row>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="red-cardddd mt-3">
                            <p className="sold-txtttt"> 3 Product(s) sold out!</p>
                            <Link>
                                <p className="small-box-footer">
                                    More info <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                                </p>
                            </Link>    
                        </div>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="blue-cardddd mt-3">
                            <p className="sold-txtttt">1 Product(s) in low stock! (Low stock limt 10)</p>
                            <Link>
                                <p className="small-box-footer">
                                    More info <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                                </p>
                            </Link>
                        </div>
                    </Col>
                </Row> */}
            </div>

            {/* <div className="Map-Section">
                <Row>
                    <Col xxl={6}>
                        <div className="card cardbox box-success">
                            <div className="card-body">
                                <p className="text-one">Weekly sales</p>
                                <p className="text">Total Sale in last week</p>
                                <div className="Bar-Chart"><CanvasJSChart options={options} containerProps={{ width: '100%', height: '300px' }} /></div>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={6}>
                        <div className="box box-success-two">
                            <Chart
                                chartType="PieChart"
                                data={dataOne}
                                options={optiontwo}
                                width={"100%"}
                                height={"400px"}
                            />
                        </div>
                    </Col>
                </Row>
            

                <Row className="">
                    <Col xxl={6}>
                        {showBox && (
                            <div className="box-two box-warning">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <h3 className="box-title">
                                                Top Sellers <small>( Month: Mar)</small>
                                            </h3>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Table responsive id="example" className="display" style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Position</th>
                                                        <th>Office</th>
                                                        <th>Age</th>
                                                        <th>Start date</th>
                                                        <th>Salary</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {datanew.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.Name}</td>
                                                            <td>{item.Position}</td>
                                                            <td>{item.Office}</td>
                                                            <td>{item.Age}</td>
                                                            <td>{item.StartDate}</td>
                                                            <td>{item.Salary}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        )}
                    </Col>
                    <Col xxl={6}>
                        {showBox && (
                            <div className="box-two box-warning box-categoris">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <h3 className="box-title">
                                                Top Sellers <small>( Month: Mar)</small>
                                            </h3>

                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Table responsive id="exampleContent" className="display" style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Position</th>
                                                        <th>Office</th>
                                                        <th>Age</th>
                                                        <th>Start date</th>
                                                        <th>Salary</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {datacontent.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.Name}</td>
                                                            <td>{item.Position}</td>
                                                            <td>{item.Office}</td>
                                                            <td>{item.Age}</td>
                                                            <td>{item.StartDate}</td>
                                                            <td>{item.Salary}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        )}
                    </Col>
                </Row>
            </div> */}
        </div>
    );
};

export default AdvanceDashboard;
