export const Per_Page_Dropdown = async (data) => {
  const a = [];
  for (let i = 5; i <= data; i *= 2) {
    a.push(i);
  }
  a.push(data);
  // console.log(a);

  return a;
};

export const RequiredIs = {
  roles: [1],
  service_term: [1, 2, 3, 4],
  service_type: [1, 2, 3],
  product_type: [1, 2, 3, 4],
};

export const Select2Data = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const getDimension = async (file) => {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
    };
    reader.readAsDataURL(file);
  });
};


export function formatDate(dateString) {
  const date = new Date(dateString);

  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  // Get formatted date string in the form "Sep 2, 2024, 7:39 AM"
  const formattedDate = date.toLocaleString('en-US', options);

  // Split the formatted date into its parts
  const [month, day, year] = formattedDate.match(/(\w+) (\d+), (\d+)/).slice(1);
  const time = formattedDate.match(/(\d+:\d+\s[APM]+)/)[0];

  // Rearrange to "6 Aug 2024, 7:39 AM"
  return `${day} ${month} ${year}, ${time}`;
}

export const IDS = {
  Roles: [1],
  Country: [9],
  State: [13],
  City: [17],
  Pincode: [21],
  WebsiteType: [79],
  Category: [29],
  SubCategory: [33],
  Language: [61],
  JobType: [62],
  Shift: [63],
  Qualification: [64],
  Job: [114],
  FAQCategory: [41],
  FAQ: [45],
  Brands: [49],
  Blog: [65],
  LatestNews: [66],
  HomeBanner: [67],
  OfferAdvertisement: [68],
  CustomerDetails: [69],
  ContactUs: [70],
  Customization: [71],
  MadeTorder: [72],
  TalkToUs: [83],
  Suggestion: [84],
  RFQ: [85],
  ResponsibilityRequest: [86],
  JobRequest: [91],
  ProductCare: [92],
  PointSetup: [93],
  PrivacyPolicy: [94],
  Responsibility: [25],
  LegalNotice: [26],
  SocialMedia: [27],
  CustomerLove: [28],
  Product: [87],
  ProductRequest: [99],
  ProductReject: [101],

  ProductType: [75],
  Material: [118],
  Occasion: [122],
  ClothSize: [126],
  TaxType: [53],
  TaxPercentage: [57],

  HelpUs: [95],
  WhoWeAre: [103],
  Values: [104],
  OurLatestAward: [105],
  AboutUsCounter: [106],
  Order: [107],

  ReturnedOrder: [111],
  Offer: [37],
  HomeOffer: [38],
  BlogOffer: [39],

  SellerRequest: [5],
  SellerDetails: [7],
  SellerRejected: [8],
 

  // Roles: [1, 2],
  // Country: [6, 7, 8, 9],
  // State: [11, 12, 13, 14],
  // City: [16, 17, 18, 19],
  // Pincode: [21, 22, 23, 24],
  // Restaurent: [26, 27, 28, 29],
  // Category: [31, 32, 33, 34],
  // HomeBanner: [36, 37, 38, 39],
  // TiffinBanner: [41, 42, 43, 44],
  // TiffinCategory: [46, 47, 48, 49],
  // FoodType: [51, 52, 53, 54],
  // AdminDishes: [56, 57, 58, 59],
  // RecommendedDishes: [61, 62, 63, 64],
  // Dishes: [66, 67, 68, 69],

  // usertype: {
  //   Restaurent: "Restaurent",
  //   Admin: "Admin",
  // },
  // order_type: {
  //   Restaurent: "Restaurent",
  //   Tiffin: "Tiffin",
  // },
};