import React, { useContext } from "react";
import { useState, useEffect } from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./ViewOffCanvance.css";
import { Context } from "../../../utils/context";
import { useForm, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import "react-phone-input-2/lib/bootstrap.css";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../common/Button";
import ModalSave from "../../common/ModelSave";

library.add(fas);

const ViewOffCanvance = (props) => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { postData, getData, IMG_URL } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const dataToSend = new FormData();
      dataToSend.append("stage", data?.stage?.value);
      dataToSend.append("email", getValues("email"));
      const response = await postData(
        `/employee/seller/request/${props.id}`,
        dataToSend
      );
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const [data, setData] = useState();
  const getSellerData = async () => {
    const res = await getData(
      `/form/customize/${props?.images}`
    );
    setData(res.data);
    reset(res.data);
  };

  useEffect(() => {
    getSellerData();
  }, [props?.images]);

  console.log(props?.images, "hhhhhhhhhhhhhhhhhhhhhhhhhh");

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%", height: "auto" }}
        placement={"end"}
        onHide={props.handleClose}
        className="offcan"
      >
        <Container className="tabss-main">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>
                  <h3>Customer Image</h3>
                </Card.Title>

                <img
                  src={`${IMG_URL}${data?.image}`}
                  alt="Image"
                  width="50px"
                  height="50px"
                  style={{ marginRight: "10px", margin: "10px" }}
                />

                <Card.Title>
                  <h3>customise Images </h3>
                </Card.Title>
                {data?.customize_images?.map((val) => (
                  <img
                    src={`${IMG_URL}${val?.image}`}
                    alt="Image"
                    width="50px"
                    height="50px"
                    style={{ marginRight: "10px", margin: "10px" }}
                  />
                ))}

                <Card.Title>
                  <h3>Gift Packing </h3>
                </Card.Title>
                {data?.company_name}
                <Card.Title>
                  <h3>Requirement </h3>
                </Card.Title>
                {data?.requirement}



              </Card.Body>
            </Card>
          </Offcanvas.Body>

{/* 
          <Row className="mt-5 pb-3">
            <div className="d-flex justify-content-center">
              <CancelButton name={"Back"} handleClose={props.handleClose} />

              <SaveButton name={"save"} handleSubmit={handleSubmit(onSubmit)} />
            </div>
          </Row> */}
        </Container>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default ViewOffCanvance;
